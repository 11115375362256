import { ThemeBordersKeys } from './borders';
import { ThemeColorsKeys } from './colors';
import { ThemeFontsKeys } from './typography';

const buttons = {
  solid: {
    type: 'fieldLabel',
    border: 'green',
    background: 'green',
    color: 'white',
    hover: {
      border: 'darkGreen',
      background: 'darkGreen',
    },
    disabled: {
      border: 'lightGreen',
      background: 'lightGreen',
    },
  },
  link: {
    type: 'fieldLabel',
    border: 'transparent',
    background: 'transparent',
    color: 'darkPrimary',
    underline: true,
    hover: {
      border: 'transparent',
      background: 'transparent',
      underline: true,
    },
    disabled: {
      color: 'lightGreen',
    },
  },
  outline: {
    type: 'fieldLabel',
    border: 'green',
    background: 'transparent',
    color: 'green',
    hover: {
      border: 'darkGreen',
      background: 'transparent',
      color: 'darkGreen',
    },
    disabled: {
      border: 'lightGreen',
      background: 'white',
      color: 'lightGreen',
    },
  },
  outlineReversed: {
    type: 'fieldInput',
    border: 'primary',
    background: 'white',
    color: 'primary',
    hover: {
      border: 'darkGreen',
      background: 'transparent',
      color: 'darkGreen',
    },
    disabled: {
      border: 'default',
      background: 'white',
      color: 'lightGreen',
    },
  },
  outlineRed: {
    type: 'fieldInput',
    border: 'red',
    background: 'white',
    color: 'red',
    hover: {
      border: 'red',
      background: 'transparent',
      color: 'red',
    },
    disabled: {
      border: 'lightGreen',
      background: 'white',
      color: 'lightGreen',
    },
  },
  tag: {
    type: 'fieldLabel',
    border: 'default',
    background: 'primary',
    color: 'white',
    hover: {
      border: 'default',
      background: 'darkPrimary',
      color: 'white',
    },
    disabled: {
      border: 'default',
      background: 'white',
      color: 'lightPrimary',
    },
  },
  tagReversed: {
    type: 'fieldLabel',
    border: 'white',
    background: 'white',
    color: 'primary',
    hover: {
      border: 'white',
      background: 'white',
      color: 'darkPrimary',
    },
    disabled: {
      border: 'default',
      background: 'white',
      color: 'lightPrimary',
    },
  },
};

export default buttons;

interface ThemeButtonBase {
  color: ThemeColorsKeys;
  background: ThemeColorsKeys;
  border: ThemeBordersKeys;
  underline?: boolean;
  uppercase?: boolean;
  animate?: boolean;
}
interface ThemeButton extends ThemeButtonBase {
  type: ThemeFontsKeys;
  hover: ThemeButtonBase;
  disabled: ThemeButtonBase;
  selected?: ThemeButtonBase;
}
export type ThemeButtonKeys = keyof typeof buttons;
export type ThemeButtons = Record<ThemeButtonKeys, ThemeButton>;
