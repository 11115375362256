import { FormSteps } from './types/formSteps';

export const SITE_NAME = 'Agria Insurance';

export const SPECIES_ID = '636b067c-dd3e-480d-87e7-d59df08c221c';

export const DOG_SPECIES_ID = 'f4372701-68d2-4459-bdba-b3be753e80d4';

export const CAT_SPECIES_ID = 'c2597178-071f-4940-825d-4d7351b5ffa4';

export const RABBIT_SPECIES_ID = '717c7e3d-09cc-4e66-807a-3527541e51d1';

export const EQUINE_SPECIES_ID = '636b067c-dd3e-480d-87e7-d59df08c221c';

export const PHONE_NUMBER = '03330 30 83 48';

export const CS_PHONE_NUMBER = '03330 30 10 00';

export const FAQ_LINK = 'https://www.agriapet.co.uk/pet-owners/products/for-dogs/faqs/';

export const MY_ACCOUNT_LOGIN_URL = 'https://www.agriapet.co.uk/my-account/login/';

export const TERMS_LINK = 'https://assets.agriapet.co.uk/terms/Agria%20Equine%20Lifetime%20TsCs.pdf';

export const PRIVACY_LINK = 'https://www.agriapet.co.uk/privacy-policy/';

export const HOME_LINK = 'https://www.agriapet.co.uk/';

export const PANEL_CONTAINER = false;

export const PANEL_BG_COLOUR = '';

export const ACTIVITY_GROUP_MAPPING = [
  {
    mappingValue: 1,
    listTitle: '',
    listItems: ['Horse at pasture', 'Retired Horse'],
  },
  {
    mappingValue: 2,
    listTitle: 'Activities from Level 1 +',
    listItems: [
      'Hacking',
      'Pleasure rides up to 15 miles',
      'Flatwork up to Prelim level',
      'Ground Work',
      'Western Riding',
    ],
  },
  {
    mappingValue: 3,
    listTitle: 'Activities from Level 1/2 +',
    listItems: [
      'Foals (aged 30 days to 12 months)',
      'Backing and starting',
      'Pole and grid work - 90cm and below',
      'Mounted games',
      'TREC',
      'Dressage - up to Novice (unaffiliated and affiliated)',
      'Showing - unaffiliated',
      'Show Jumping - 90cm and below (unaffiliated and affiliated)',
      'Vaulting',
      'Endurance rides - 25 miles and below',
      'Riding and Pony Clubs',
    ],
  },
  {
    mappingValue: 4,
    listTitle: 'Activities from Levels 1/2/3 +',
    listItems: [
      'Showing - affiliated',
      'Hunter Trials - 90cm and below',
      'Dressage - below Elementary',
      'Eventing - 90cm and below (unaffiliated and affiliated)',
      'Jump Cross',
      'Driving - non-competitive',
      'Show Jumping - 110cm and below (unaffiliated and affiliated)',
      'Tent Pegging',
      'Working Equitation',
      'Barrel Racing',
      'Team Chasing - 90cm and below',
    ],
  },
  {
    mappingValue: 5,
    listTitle: 'Activities from Levels 1/2/3/4  +',
    listItems: [
      'Competitive driving - excluding harness racing (inclusive of pacing and trotting)',
      'Dressage - Elementary and above',
      'Endurance Rides - 25 miles and above',
      'Eventing - 100cm to intermediate (unaffiliated and affiliated)',
      'Hunting',
      'Hunter Trials - 95cm and above',
      'Show jumping - 115cm and above (unaffiliated and affiliated)',
    ],
  },
  {
    mappingValue: 6,
    listTitle: 'Activities from Levels 1/2/3/4/5  +',
    listItems: [
      'Eventing - Advanced',
      'Horseball',
      'Point to Point',
      'Polo',
      'Polocrosse',
      'Team Chasing - 90cm and above',
    ],
  },
];

interface GaSteps {
  path: string;
  stage: string;
  step: string;
}
export const GA_STEPS: Record<FormSteps, GaSteps> = {
  aboutYourHorse: {
    path: '/quote/about-your-horse',
    stage: 'details',
    step: 'About your horse',
  },
  aboutYou: {
    path: '/quote/about-you',
    stage: 'details',
    step: 'About you',
  },
  yourHorsesHistory: {
    path: '/quote/your-horses-history',
    stage: 'details',
    step: 'Your horse’s history',
  },
  activityLevel: {
    path: '/quote/activity-level',
    stage: 'cover options',
    step: 'Activity level',
  },
  policyStartDate: {
    path: '/quote/start-date',
    stage: 'cover options',
    step: 'Start date',
  },
  coverLevel: {
    path: '/quote/cover-level',
    stage: 'cover options',
    step: 'Cover level',
  },
  excessOptions: {
    path: '/quote/excesses',
    stage: 'cover options',
    step: 'excesses',
  },
  extras: {
    path: '/quote/extras',
    stage: 'cover options',
    step: 'extras',
  },
  declarations: {
    path: '/quote/declarations',
    stage: 'declarations',
    step: 'declarations',
  },
  checkYourDetails: {
    path: '/quote/summary',
    stage: 'summary',
    step: 'summary',
  },
  checkHorseDetails: {
    path: '/quote/summary',
    stage: 'summary',
    step: 'summary',
  },
  communicationPreferences: {
    path: '/quote/summary',
    stage: 'summary',
    step: 'summary',
  },
  paymentOptions: {
    path: '/quote/payment',
    stage: 'payment',
    step: 'payment',
  },
  pay: {
    path: '/quote/pay',
    stage: 'pay',
    step: 'pay',
  },
  confirmation: {
    path: '/quote/confirmation',
    stage: 'confirmation',
    step: 'confirmation',
  },
};
